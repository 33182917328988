import { useState, useEffect } from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
import Hamburger from "@components/Layouts/App/Hamburger";
import { withUser } from "@containers/Auth";
import withIsMobile from "@containers/withIsMobile";
import LogoutButton from "@containers/LogoutButton";
import styles from "./index.module.scss";
import logo from "@assets/images/LFL_RGB_HorizonalTaglineLogo_White@3x.png";
import { classNames } from "@utils";

const AdminOptions = () => (
  <div className={styles.dropdown}>
    <a
      href="/"
      activeClassName={styles.active}
      title="Admin Options"
      className={styles.dropbtn}
    >
      Admin Options
    </a>

    <div class={styles.dropdownContent}>
      <NavLink
        className="selectable"
        activeClassName={styles.active}
        title="Sponsors"
        to="/sponsors"
      >
        Sponsors
      </NavLink>
      <NavLink
        className="selectable"
        activeClassName={styles.active}
        title="Indicators"
        to="/indicators"
      >
        Indicators
      </NavLink>
      <NavLink
        className="selectable"
        activeClassName={styles.active}
        title="Announcements"
        to="/announcements"
      >
        Announcements
      </NavLink>
      <NavLink
        className="selectable"
        activeClassName={styles.active}
        title="Donation Messages"
        to="/donationMessages"
      >
        Donation Messages
      </NavLink>
    </div>
  </div>
);

const Links = withUser(({ className, isMobile, user }) => (
  <div className={classNames(styles.links, className)}>
    {isMobile && (
      <img
        src={logo}
        alt="Little Free Library Small Logo"
        className={styles.mobileLogo}
      />
    )}
    {user.is_admin && (
      <NavLink
        className="selectable"
        activeClassName={styles.active}
        title="Users"
        to="/users"
      >
        Users
      </NavLink>
    )}
    <NavLink
      className="selectable"
      activeClassName={styles.active}
      title="Libraries"
      to="/libraries"
    >
      Libraries
    </NavLink>
    {user.is_admin && (
      <NavLink
        className="selectable"
        activeClassName={styles.active}
        title="Issues"
        to="/issues"
      >
        Issues
      </NavLink>
    )}
    {user.is_admin && (
      <NavLink
        className="selectable"
        activeClassName={styles.active}
        title="Transfers"
        to="/transfers"
      >
        Transfers
      </NavLink>
    )}
    {user.is_admin && (
      <NavLink
        className="selectable"
        activeClassName={styles.active}
        title="Charters"
        to="/charters"
      >
        Charters
      </NavLink>
    )}
    {user.is_admin && (
      <NavLink
        className="selectable"
        activeClassName={styles.active}
        title="Analytics"
        to="/analytics"
      >
        Analytics
      </NavLink>
    )}
    {user.is_steward && (
      <NavLink
        className="selectable"
        activeClassName={styles.active}
        title="Account"
        to="/account"
      >
        Account
      </NavLink>
    )}
    {user.is_admin && <AdminOptions />}
    {user.is_admin && (
      <NavLink
        className={styles.mobileOnly}
        activeClassName={styles.active}
        title="Sponsors"
        to="/sponsors"
      >
        Sponsors
      </NavLink>
    )}
    {user.is_admin && (
      <NavLink
        className={styles.mobileOnly}
        activeClassName={styles.active}
        title="Indicators"
        to="/indicators"
      >
        Indicators
      </NavLink>
    )}
    {user.is_admin && (
      <NavLink
        className={styles.mobileOnly}
        activeClassName={styles.active}
        title="Announcements"
        to="/announcements"
      >
        Announcements
      </NavLink>
    )}
    <LogoutButton />
  </div>
));

const DesktopLinks = () => (
  <div className={styles.content}>
    <Links />
  </div>
);

const MobileLinks = () => {
  const [isOpen, setIsOpen] = useState(),
    location = useLocation();
  useEffect(() => {
    setIsOpen(false);
  }, [location]);
  return (
    <>
      <Hamburger onClick={() => setIsOpen((prev) => !prev)} isOpen={isOpen} />

      <div className={classNames(styles.overlay, isOpen && styles.open)} />

      <Links className={isOpen ? styles.open : undefined} isMobile />
    </>
  );
};
const LinksContainer = withIsMobile(
  (w) => (w.isMobile ? <MobileLinks /> : <DesktopLinks />),
  1024
);

export const Header = ({ children }) => (
  <header className={styles.container}>
    <div className={styles.innerWrapper}>
      <Link to="/" className={styles.logo}>
        <img src={logo} alt="Little Free Library Main Logo" />
      </Link>
      {children}
    </div>
  </header>
);
const LoggedInHeader = () => (
  <Header>
    <LinksContainer />
  </Header>
);

export default LoggedInHeader;
