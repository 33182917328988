import {makeRequest,getRequest} from '@utils/api';
import { stringifyQuery } from '@utils';

export const createUser = (user, url) => makeRequest("POST",url,{
  user
})

export const updateUser = (user, url) => makeRequest("PATCH",url,{
  user
})

export const sendInvitation = (id) => makeRequest("POST", `/users/${id}/invite.json`)

export const resendConfirmation = (email) => makeRequest("POST", "/users/confirmation.json", {
  user: {
    email
  }
})

export const exportUsers = (query) => getRequest(`/user/export.json${query ? `?${stringifyQuery(query)}` : ''}`)
