import {Link} from 'react-router-dom';
import withIsMobile from '@containers/withIsMobile';
import styles from './index.module.scss';
import {classNames} from '@utils';
import backIcon from '@assets/images/arrow_back-24px.svg';

const Header = withIsMobile(({isMobile,back,right,title,left,submit}) => (
  <div className={styles.header}>
    <div className={styles.headerInnerWrapper}>
      <div className={styles.left}>
        <div className={styles.titleWrapper}>
          {back && (
            <Link to={typeof back === "string" ? back : ".."} className={styles.backLink}><img alt="Back" src={backIcon} className={styles.backIcon} /></Link>
          )}
          <div className={styles.title}>{title}</div>
        </div>

        {left && (
          <div className={styles.leftContent}>
            {left}
          </div>
        )}

      </div>

      {right && (
        <div className={styles.right}>
          {right}
        </div>
      )}
    </div>
  </div>
), 1024);

const Page = ({children,back,right,left,title,className,submit}) => (
  <div className={styles.container}>
    <Header back={back} right={right} left={left} title={title} submit={submit} />
    <div className={classNames(styles.content, className)}>
      {children}
    </div>
  </div>
)

export default Page;
