import Table from '@components/Table';
import styles from './index.module.css'

import { formatDistance, parseISO} from 'date-fns'

const formatDate  = (date) => {
  return date ? formatDistance(parseISO(date), new Date(), { addSuffix: true }): ''
}

const CharterNumberListPage = ({announcements, text = "No announcements found"}) => (
  <Table
    cols={["Title", "Link", "Indicator", "Active", "Recurring","Start Time", "End Time"]}
    rows={announcements}
    renderRow={(announcement) => (<>
      <div className={styles.padding}>{announcement.Title__c}</div>
      <div className={styles.padding}><a target={'_blank'} href={announcement.LinkUrl__c}>{announcement.LinkTitle__c}</a></div>
      <div className={styles.padding}>{announcement?.app_flag?.Name}</div>
      <div className={styles.padding}>{announcement.Active__c ? "Active" : "Inactive"}</div>
      <div className={styles.padding}>{announcement.Recurring__c? "Yes": "No"}</div>
      <div className={styles.padding}>{formatDate(announcement?.Start_Time__c)}</div>
      <div className={styles.padding}>{formatDate(announcement?.End_Time__c)}</div>
  </>)}
    placeholder={{to: "new", text,hideButton: true}}
  />
)

export default CharterNumberListPage;
