import {PublicForm} from '@components/Layouts/Public';
import InputComponent,{Input} from '@components/Form/Fields/Input';
import {useMapYourLibraryAuthSuccessHandler} from '@components/Public/MapYourLibrary/Beginning';
import {useAuthDispatch,loginAction} from '@containers/Auth';
import withParams from '@containers/withParams';
import {loginSchema,mapYourLibraryLoginSchema} from '@utils/validation/auth';
import {login,mapYourLibraryLogin} from '@utils/api/auth';

const LoginPage = ({children,...props}) => (
  <PublicForm {...props} buttonText="Log In"  >
    <InputComponent type="email" white name="email" placeholder="Email" secondary round />
    <InputComponent type="password" white name="password" placeholder="Password" secondary round />
    {children}
  </PublicForm>
)

export const MapYourLibraryLoginPage = withParams(({params:{email_code}}) => {
  const handleSuccess = useMapYourLibraryAuthSuccessHandler("../success")
  return(
    <LoginPage
      title="Log In To Complete Your Steward Account Setup"
      onSubmit={mapYourLibraryLogin}
      to={false}
      url="/login"
      defaultValues={{email_code}}
      onSuccess={handleSuccess}
      validationSchema={mapYourLibraryLoginSchema}
      link={[{to: "../password/forgot", text: "Forgot Password?"},{to: "..", text: "Back"}]}
      homePage=".."
    >
      <Input name="email_code" className="hidden" />
    </LoginPage>
  )
})

const LoginPageContainer = () => {
  const dispatch = useAuthDispatch(),
        handleSuccess = ({response}) => dispatch(loginAction(response));
  return(
    <LoginPage title="Login" url="/login" onSubmit={login} to={false} onSuccess={handleSuccess} validationSchema={loginSchema} link={{to: "/password/forgot", text: "Forgot Password?"}} />
  )
}

export default LoginPageContainer;
