import { Link, useNavigate } from 'react-router-dom';
import { PublicHeader } from '@components/Layouts/Public';
import Form from '@components/Form';
import Button from '@components/Button';
import InputComponent, { Input } from '@components/Form/Fields/Input';
import { useAuthDispatch, loginAction } from '@containers/Auth';
import AutocapitalizeInput from '@components/Form/Fields/AutocapitalizeInput';
import withParams from '@containers/withParams';
import styles from './index.module.scss';
import { mapYourLibraryRegistrationSchema } from '@utils/validation/auth';
import { mapYourLibraryRegistrationRequest } from '@utils/api/auth';

export const useMapYourLibraryAuthSuccessHandler = (to = "success") => {
  const dispatch = useAuthDispatch(),
    navigate = useNavigate();
  return ({ response }) => {
    navigate(to)
    dispatch(loginAction(response))
  }
}

const RegistrationForm = withParams(({ params: { email_code } }) => {
  const handleSuccess = useMapYourLibraryAuthSuccessHandler()
  return (
    <Form defaultValues={{ email_code, tos_accepted: true }} to={false} onSuccess={handleSuccess} validationSchema={mapYourLibraryRegistrationSchema} toast="Your account has been created" onSubmit={mapYourLibraryRegistrationRequest}>
      <AutocapitalizeInput name="first_name" placeholder="First Name" secondary round />
      <AutocapitalizeInput name="last_name" placeholder="Last Name" secondary round />
      <InputComponent type="email" name="email" placeholder="Email" secondary round />
      <InputComponent type="password" name="password" placeholder="Password" secondary round />
      <Input name="email_code" className="hidden" />
      <Input name="tos_accepted" className="hidden" />
      <Button type="submit" light>Sign Up</Button>
      <div className={styles.terms}>
        <div>By creating an account, I agree to the Little Free Library</div>
        <div><a href="https://littlefreelibrary.org/privacy-policy" target="_blank">Mobile Terms and Conditions</a></div>
      </div>
    </Form>
  )
})
const MapYourLibraryWelcomePage = () => (<>
  <PublicHeader
    title="Set Up Your Steward Account"
    margin
    text="Create a free account below to be able to map and edit your Little Free Library locations."
  />
  <RegistrationForm />
  <div className={`${styles.link} ${styles.margin}`}>
    <div>
      Already have an account?
    </div>
    <div>
      <Link to="login">Click here to log in.</Link>
    </div>
  </div>
  <div className={styles.link}>
    <div>
      Not ready to set up an account?
    </div>
    <div>
      <a href="https://littlefreelibrary.org/mapyourlibrary/" target="_blank">Click here to add library to map without account.</a>
    </div>
  </div>
</>)

export default MapYourLibraryWelcomePage;
