import Page from "@components/Layouts/Page";
import Content from "@components/Layouts/Content";
import Form from "@components/Form";
import Switch from "@components/Form/Fields/Switch";
import InputComponent from "@components/Form/Fields/Input";
import AppInfoSelect from '@containers/AppInfoSelect';
import Button from "@components/Button";
import { announcementSchema } from "@utils/validation/announcement";
import styles from "./index.module.css";
import ValueField from "../ValueField";

const AnnouncementFormPage = ({ form, page, announcement }) => (
  <Form validationSchema={announcementSchema} {...form}>
    <Page
      {...page}
      right={
        <Button medium type="submit">
          Save
        </Button>
      }
    >
      <Content title="Announcement Information">
        <div className={styles.content}>
          <InputComponent
            name="Title__c"
            placeholder="Enter Announcement Title"
            label="Title"
          />
          <InputComponent
            as="textarea"
            name="Body__c"
            placeholder="Enter Announcement Body"
            label="Body"
          />
          <AppInfoSelect
            name="app_flag_id"
            label="Indicator"
            resource="app_flags"
            placeholder={true}
            valueAccessor="id"
            labelAccessor="Name"
          />
          <InputComponent
            name="LinkTitle__c"
            placeholder="Enter Announcement Link Title"
            label="Link Title"
          />
          <InputComponent
            name="LinkUrl__c"
            placeholder="Enter Announcement Link Url"
            label="Link Url"
          />
          <Switch label="Active" name="Active__c" />
          <Switch label="Recurring" name="Recurring__c" />
          <InputComponent
            type="date"
            name="Start_Time__c"
            label="Enter Announcement Start Time"
          />
          <InputComponent
            type="date"
            name="End_Time__c"
            label="Enter Announcement End Time"
          />
          <ValueField name="Audience_value" label="Audience value" defaultValue={form?.defaultValues?.Audience_value}/>
        </div>
      </Content>
    </Page>
  </Form>
);

export default AnnouncementFormPage;
