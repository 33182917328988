import {makeRequest} from '@utils/api';
import {addLibraryFlag,removeLibraryFlag} from '@utils/api/flag';

const findUpdatedFlags = (flags,initialFlags) => ({
  added: flags.filter(({library_flag_id}) => {
    const libraryFlagId = Number(library_flag_id)
    return !initialFlags.find(({id}) => id === libraryFlagId)
  }).map(({app_flag_id}) => app_flag_id),
  removed: initialFlags.filter(({id}) => !flags.find(({library_flag_id}) => {
    const libraryFlagId = Number(library_flag_id)
    return id === libraryFlagId
  })).map(({id}) => id)
})

export const libraryRequest = ({image_urls,...library_c}, url,action = "POST") => makeRequest(action,url,{
  library_c
})

const addLibraryFlags = (libraryId,flags) => new Promise(async (resolve,reject) => {
  if(flags.length > 0){
    for (var i = 0; i < flags.length; i++) {
      await addLibraryFlag(libraryId,flags[i])
    }
  }
  resolve()
})

const removeLibraryFlags = (flags) => new Promise(async (resolve,reject) => {
  if(flags.length > 0){
    for (var i = 0; i < flags.length; i++) {
      await removeLibraryFlag(flags[i])
    }
  }
  resolve()
})

const updateLibraryFlags = (libraryId,activeFlags,initialActiveFlags) => new Promise(async (resolve,reject) => {
  const {added,removed} = findUpdatedFlags(activeFlags,initialActiveFlags);
  if(removed?.length > 0){
    await removeLibraryFlags(removed)
  }
  if(added?.length > 0){
    await addLibraryFlags(libraryId,added)
  }
  resolve()
})

export const updateLibrary = async ({active_flags,Announcement__c,DownUntil__c,id,...library_c},url,initialValues) => new Promise(async (resolve,reject) => {
  try {
    if(initialValues){
      await updateLibraryFlags(id,active_flags,initialValues.active_flags)
    }
    resolve(await libraryRequest(library_c,url,"PATCH"))
  } catch (e) {
    reject(e)
  }
})

export const createLibrary = async ({active_flags,...library_c},url) => new Promise(async (resolve,reject) => {
  try {
    const response = await libraryRequest(library_c,url)

    await addLibraryFlags(response.id,active_flags)

    resolve(response)
  } catch (e) {
    reject(e)
  }
})
