import { useState, useCallback, useEffect, useRef } from 'react';
import { Loader } from '@components/Loader';
import Section from '@components/Section';
import lockIcon from '@assets/images/lock_black_24dp.svg';
import myLocationIcon from '@assets/images/my_location_black_24dp.svg';
import { useToastAddAction } from '@containers/ToastContext';
import FilterPanel, { PanelButton, InfoSection, InfoModal } from './FilterPanel';
import TextFilter from './TextFilter';
import styles from './index.module.scss';
import nearMeIcon from '@assets/images/near_me.svg';

const getFilterType = (filters) => {
  if(filters.name){
    return "name"
  } else if(filters.charter){
    return "charter"
  }
  return "near"
}
const initialSearchValues = {
  type: "near",
  near: "",
  charter: "",
  name: ""
}
export default function Filters({
  visible,
  isLoading,
  isEmpty,
  filters,
  updateFilters,
  isMobile,
  setUserPos,
  displayLabel,
  setDisplayLabel
}){
  const initialFilterType = getFilterType(filters)
  const [search,setSearch] = useState(initialSearchValues)
  const [modal,setModal] = useState(false)
  const prevSearchType = useRef("near");
  const addToast = useToastAddAction();
  const isSmall = (search.type === "nearme" || displayLabel) ? true : false
  const handleSearchTypeChange = (type) => setSearch(prevSearch => ({...prevSearch,type}))
  const handleSearchStringChange = (e) => setSearch(prevSearch => ({...prevSearch,[prevSearch.type]: e.target.value}))
  const handleSubmit = () => updateFilters({
    [search.type]: search[search.type]
  })
  const clearDisplay= () => {
    if(search.type === "nearme"){
      handleSearchTypeChange(prevSearchType.current)
    }
    if(displayLabel){
      setDisplayLabel()
    }
  }
  const getUserPosition = useCallback((initialRequest) => {
    navigator?.geolocation?.getCurrentPosition(function(geoposition) {
      setUserPos({
        lat: geoposition.coords.latitude,
        lng: geoposition.coords.longitude
      })
    }, function(error){
      if(!initialRequest){
        setModal(true)
        addToast("Little Free Library does not have permission to use your location")
        handleSearchTypeChange("near")
      }
    }, {
      enableHighAccuracy: true
    })
  },[])
  useEffect(() => {
    if(search.type === "nearme"){
      getUserPosition()
      setDisplayLabel()
    } else {
      prevSearchType.current = search.type
    }
  },[search.type])
  useEffect(() => {
    getUserPosition(true)
  },[])
  return visible ? (<>
    <div className={styles.filters}>
      <TextFilter isSmall={isSmall} getUserPosition={getUserPosition} searchType={search.type} searchString={search[search.type]} handleSearchTypeChange={handleSearchTypeChange} handleSearchStringChange={handleSearchStringChange} onSubmit={handleSubmit} filters={filters} isMobile={isMobile} clearDisplay={clearDisplay} displayLabel={displayLabel} />
      <FilterPanel isSmall={isSmall} onApply={updateFilters} defaultIndicators={filters.app_flag_ids} defaultDistance={filters.distance} />
      <PanelButton src={nearMeIcon} className={styles.nearMeButton} title="Find Libraries Near Me" onClick={() => handleSearchTypeChange("nearme")} />
    </div>
    {isLoading && (
      <div className={styles.fetcherWrapper}>
        <Loader className={styles.fetcher} dark />
      </div>
    )}
    {modal && (
      <InfoModal close={() => setModal(false)} className={styles.modal}>
        <InfoSection title="Give Little Free Library location permission in your browser">
          To center the map where you are, you must give Little Free Library permission to find your location.
          <div className={styles.break} />
          On macOS, you may need to turn on Location Services for your browser before you can grant Little Free Library permission in your browser:
          <br />
          <ol>
            <li>On your computer, open <span className={styles.bold}>System Preferences</span> > <span className={styles.bold}>Security & Privacy Preferences</span> > <span className={styles.bold}>Privacy</span> > <span className={styles.bold}>Location Services</span>.</li>
            <li>To allow for changes, at the bottom left, click the lock.</li>
            <li>Next to "Enable Location Services," check the box.</li>
            <li>Next to your browser, check the box.</li>
            <li>Give Little Free Library location permission in your browser.</li>
          </ol>

          <Section title="Chrome" collapsible wrapperClassName={styles.noBorder} className={styles.noPadding}>
            <ol>
              <li>On your computer, open Chrome and go to <a href="/ourmap" target="_blank">Little Free Library</a>.</li>
              <li>
                At the bottom right, click My location <img src={myLocationIcon} className={styles.icon} />.
                <ul>
                  <li>If you're asked to share your location, choose <span className={styles.bold}>Allow</span>.</li>
                  <li>If the blue dot appears and shows your location, then Little Free Library already has location permission in your browser.</li>
                  <li>If there’s a message that says "Little Free Library does not have permission to use your location," continue to the next steps.</li>
                </ul>
              </li>
              <li>To the left of the web address, click Lock <img src={lockIcon} className={styles.icon} />.</li>
              <li>Click <span className={styles.bold}>Site settings</span>.</li>
              <li>To the right of "Location," choose <span className={styles.bold}>Allow</span>.</li>
              <li>Reload the Little Free Library page and click My location <img src={myLocationIcon} className={styles.icon} /> again.</li>
            </ol>
          </Section>
          <Section title="Firefox" collapsible className={styles.noPadding}>
            <ol>
              <li>On your computer, open Firefox and go to <a href="/ourmap" target="_blank">Little Free Library</a>.</li>
              <li>
                At the bottom right, click My location <img src={myLocationIcon} className={styles.icon} />.
                <ul>
                  <li>When asked to share your location, choose <span className={styles.bold}>Allow</span> or <span className={styles.bold}>Allow location access</span>.</li>
                  <li>You might find "Show your location" instead.</li>
                  <li>If the blue dot appears and shows your location, then Little Free Library already has location permission in your browser.</li>
                  <li>If there’s a message that says "Little Free Library does not have permission to use your location," continue to the next steps.</li>
                </ul>
              </li>
              <li>To the left of the web address, click Lock <img src={lockIcon} className={styles.icon} />.</li>
              <li>Click <span className={styles.bold}>Connection secure</span> > <span className={styles.bold}>More information</span>.</li>
              <li>Select <span className={styles.bold}>Permissions</span>.</li>
              <li>To the right of "Access your location," uncheck <span className={styles.bold}>Use Default</span>. Then, choose <span className={styles.bold}>Allow</span>.</li>
              <li>Reload the Little Free Library page and click My location <img src={myLocationIcon} className={styles.icon} /> again.</li>
            </ol>
          </Section>
          <Section title="Safari" collapsible className={styles.noPadding}>
            Important: To use Safari, you must have Location Services enabled.
            <ol>
              <li>On your computer, open Safari and go to <a href="/ourmap" target="_blank">Little Free Library</a>.</li>
              <li>
                At the bottom right, click My location <img src={myLocationIcon} className={styles.icon} />.
                <ul>
                  <li>You should be asked to share your location. Choose <span className={styles.bold}>Allow</span>.</li>
                  <li>If the blue dot appears and shows your location, then Little Free Library already has location permission in your browser.</li>
                  <li>If there’s a message that says "Little Free Library does not have permission to use your location," continue to the next steps.</li>
                </ul>
              </li>
            </ol>
            To turn on Location Services in Safari:
            <ol>
              <li>Click the Apple icon > <span className={styles.bold}>System Preferences</span>.</li>
              <li>Click the "Security & Privacy" icon. Then, select <span className={styles.bold}>Privacy</span>.</li>
              <li>Check <span className={styles.bold}>Enable Location Services</span>.</li>
              <li>In the list, check <span className={styles.bold}>Safari</span>.</li>
              <li>Click the Lock icon so that it's locked.</li>
            </ol>
          </Section>
          <Section title="Microsoft Edge" collapsible className={styles.noPadding}>
            <ol>
              <li>On your computer, open Microsoft Edge and go to <a href="/ourmap" target="_blank">Little Free Library</a>.</li>
              <li>
                At the bottom right, click My location <img src={myLocationIcon} className={styles.icon} />.
                <ul>
                  <li>If you’re asked to share your location, choose <span className={styles.bold}>Allow</span>.</li>
                  <li>If the blue dot appears and shows your location, then Little Free Library already has location permission in your browser.</li>
                  <li>If there’s a message that says "Little Free Library does not have permission to use your location," continue to the next steps.</li>
                </ul>
              </li>
              <li>To the left of the web address, click Lock <img src={lockIcon} className={styles.icon} />.</li>
              <li>Click <span className={styles.bold}>Permissions for this site</span>.</li>
              <li>To the right of "Location," choose <span className={styles.bold}>Allow</span>.</li>
              <li>Reload the Little Free Library page and click  My location <img src={myLocationIcon} className={styles.icon} />.</li>
            </ol>
          </Section>
        </InfoSection>
      </InfoModal>
    )}
  </>) : null
}
