import {useAuthDispatch,logoutAction} from '@containers/Auth';
import {classNames} from '@utils';


const LogoutButton = ({className,children,as: WrapperComponent = "button",...props}) => {
  const dispatch = useAuthDispatch(),
        handleClick = () => dispatch(logoutAction())
  return <WrapperComponent {...props} type="button" onClick={handleClick} className={classNames("selectable",className)} title="Log Out">{children || "Log Out"}</WrapperComponent>
}

export default LogoutButton
