import {string, object} from 'yup';

export const librarySchema = object().shape({
  Facebook__c: string().matches(
    /^(https?:\/\/)?((w{3}\.)?)facebook.com\/.*/i,
    {message:'Not a valid Facebook URL',excludeEmptyString:true}
  ).nullable(),
  Instagram__c: string().matches(
    /^(https?:\/\/)?((w{3}\.)?)instagram.com\/.*/i,
    {message:'Not a valid Instagram URL',excludeEmptyString:true}
  ).nullable(),
  Twitter__c: string().matches(
    /^(https?:\/\/)?((w{3}\.)?)twitter.com\/.*/i,
    {message:'Not a valid Twitter URL',excludeEmptyString:true}
  ).nullable(),
  Pinterest__c: string().matches(
    /^(https?:\/\/)?((w{3}\.)?)pinterest.com\/.*/i,
    {message:'Not a valid Pinterest URL',excludeEmptyString:true}
  ).nullable(),
  Street__c: string().test('Has no coordinates and no street address',"Library Street Address is required",function(){
    return (this.parent.Latitude_Form__c && this.parent.Longitude_Form__c) || this.parent.Street__c;
  }).nullable(),
  Country__c: string().test('Has no coordinates and no country',"Country is required",function(){
    return (this.parent.Latitude_Form__c && this.parent.Longitude_Form__c) || this.parent.Country__c;
  }).nullable(),
  City__c: string().test('Has no coordinates and no city',"City is required",function(){
    return (this.parent.Latitude_Form__c && this.parent.Longitude_Form__c) || this.parent.City__c;
  }).nullable(),
  State_Province_Region__c: string().test('Has no coordinates and no state',"State/Province is required",function(){
    return (this.parent.Latitude_Form__c && this.parent.Longitude_Form__c) || this.parent.State_Province_Region__c;
  }).nullable(),
  Postal_Zip_Code__c: string().test('Has no coordinates and no postal code',"Postal Code is required",function(){
    return (this.parent.Latitude_Form__c && this.parent.Longitude_Form__c) || this.parent.Postal_Zip_Code__c;
  }).nullable()
});
