import { useState, useCallback, useRef, useEffect, memo } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  StreetViewPanorama,
  Circle,
  MarkerShapeCircle,
} from "@react-google-maps/api";
import axios from "axios";
import { useToastAddAction } from "@containers/ToastContext";
import withIsMobile from "@containers/withIsMobile";
import LibraryInfoWindow from "./InfoWindow";
import LibraryMarkers from "./LibraryMarkers";
import Filters from "./Filters";
import { debounce } from "@utils";
import styles from "./index.module.css";

const DEFAULT_CENTER = {
  lat: 44.96983239825206,
  lng: -93.19499318876275,
};

const isLatitude = (num) => isFinite(num) && Math.abs(num) <= 90;
const isLongitude = (num) => isFinite(num) && Math.abs(num) <= 180;
const getCoordsFromNearFilter = (near) => {
  const coords = near.split(",");
  if (coords.length === 2 && isLatitude(coords[0]) && isLongitude(coords[1])) {
    return {
      lat: Number(coords[0]),
      lng: Number(coords[1]),
    };
  }
};
const trimFilters = ({ near, charter, name, ...filters }) => {
  const trimmedFilters = { ...filters };
  if (near) {
    trimmedFilters.near = near.trim();
  }
  if (charter) {
    trimmedFilters.charter = charter.trim();
  }
  if (name) {
    trimmedFilters.name = name.trim();
  }
  return trimmedFilters;
};
export const useLibraries = (filters = {}) => {
  const addToast = useToastAddAction();
  const [isLoading, setIsLoading] = useState();
  const [data, setData] = useState();

  useEffect(() => {
    if (filters.near || filters.charter || filters.name) {
      let didCancel = false;
      const fetchData = async () => {
        try {
          setIsLoading(true);
          const trimmedFilters = trimFilters(filters);
          const res = await axios.get(
            `${process.env.REACT_APP_API_URL}/library/pin.json`,
            {
              params: {
                page_size: 50,
                ...trimmedFilters,
              },
            }
          );
          if (!didCancel) {
            setData({
              filters: trimmedFilters,
              ...res.data,
            });
            if (res?.data?.libraries?.length === 0) {
              addToast(
                "No results found. Try widening your search or changing search criteria."
              );
            }
          }
        } catch (e) {
          addToast("An unexpected error occurred. Please try again.");
        } finally {
          if (!didCancel) {
            setIsLoading(false);
          }
        }
      };
      fetchData();
      return () => {
        didCancel = true;
      };
    }
  }, [filters]);

  return {
    isLoading,
    data,
  };
};

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}
function getDistanceFromCoords(lat1, lon1, lat2, lon2) {
  var R = 6371;
  var dLat = deg2rad(lat2 - lat1);
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
    Math.cos(deg2rad(lat2)) *
    Math.sin(dLon / 2) *
    Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d * 0.621371;
}

const removeSearchFilters = ({ near, name, charter, ...filters }) => filters;

const LibraryMapPage = memo(
  ({ initialFilters = { distance: 50, app_flag_ids: [] }, isMobile }) => {
    const { isLoaded } = useJsApiLoader({
      id: "google-map-script",
      googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    });
    const [filters, setFilters] = useState(initialFilters);
    const { data, isLoading } = useLibraries(filters);

    const [mapRef, setMapRef] = useState(null);
    const [userPos, setUserPos] = useState();
    const [displayLabel, setDisplayLabel] = useState();
    const blockCenterChangedRequest = useRef();
    const [streetViewRef, setStreetViewRef] = useState(null);
    const [isStreetViewOpen, setIsStreetViewOpen] = useState(false);
    const [openLibraryInfo, setOpenLibraryInfo] = useState(null);
    const onLoad = useCallback((map) => {
      setMapRef(map);
    }, []);
    const handleStreetViewLoad = useCallback((sv) => {
      setStreetViewRef(sv);
    }, []);
    const handleMarkerClick = useCallback(async (library) => {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/libraries/${library.id}.json`
      );
      setOpenLibraryInfo(res.data);
    }, []);
    const handleMapClick = useCallback(() => {
      setOpenLibraryInfo();
    }, []);
    const moveMap = useCallback(
      (coords, block = true, zoom = 14) => {
        if (mapRef) {
          if (block) {
            blockCenterChangedRequest.current = true;
          }
          mapRef.panTo(coords);
          if (zoom) {
            mapRef.setZoom(
              typeof zoom === "function" ? zoom(mapRef.getZoom()) : zoom
            );
          }
        }
      },
      [mapRef]
    );
    const handleClusterClick = useCallback(
      (cluster) => {
        moveMap(
          {
            lat: cluster.center.lat(),
            lng: cluster.center.lng(),
          },
          true,
          (zoom) => (zoom < 12 ? 12 : zoom + 1.5)
        );
      },
      [moveMap]
    );
    const handleStreetViewVisibleChange = useCallback(() => {
      setIsStreetViewOpen(streetViewRef.visible);
    }, [streetViewRef]);

    const onUnmount = useCallback((map) => {
      setMapRef(null);
    }, []);
    const updateFilters = useCallback(
      (newFilters) =>
        setFilters((prevFilters) => ({
          ...(newFilters.near || newFilters.charter || newFilters.name
            ? removeSearchFilters(prevFilters)
            : prevFilters),
          ...newFilters,
        })),
      []
    );
    const handleCenterChange = debounce(() => {
      if (mapRef && mapRef.getZoom() > 8) {
        if (blockCenterChangedRequest.current) {
          blockCenterChangedRequest.current = false;
        } else {
          const { lat, lng } = mapRef.getCenter().toJSON();
          updateFilters({
            near: `${lat},${lng}`,
          });
          setDisplayLabel("Map");
        }
      }
    }, 1000);
    useEffect(() => {
      if (
        openLibraryInfo &&
        !data.libraries.find((l) => l.id === openLibraryInfo.id)
      ) {
        setOpenLibraryInfo();
      }
    }, [openLibraryInfo, data]);
    useEffect(() => {
      if (openLibraryInfo && mapRef) {
        const hasImage =
          openLibraryInfo.image_urls && openLibraryInfo.image_urls.length > 0;
        moveMap(
          {
            lat:
              openLibraryInfo.Library_Geolocation__Latitude__s +
              Math.abs(
                mapRef.getBounds().getNorthEast().lat() -
                mapRef.getBounds().getSouthWest().lat()
              ) *
              (isMobile
                ? hasImage
                  ? 0.45
                  : 0.35
                : hasImage > 0
                  ? 0.4
                  : 0.3),
            lng: openLibraryInfo.Library_Geolocation__Longitude__s,
          },
          true,
          false
        );
      }
    }, [openLibraryInfo, moveMap, isMobile]);
    useEffect(() => {
      if (data?.libraries?.length > 0) {
        if (data.filters?.near) {
          const coords = getCoordsFromNearFilter(data.filters.near);
          if (!coords && data.latitude && data.longitude) {
            moveMap({
              lat: Number(data.latitude),
              lng: Number(data.longitude),
            });
          }
        } else if (data.filters?.charter && data.libraries?.length > 0) {
          handleMarkerClick(data.libraries[0])
          mapRef.setZoom(14);
        } else if (data.latitude && data.longitude) {
          moveMap({
            lat: Number(data.latitude),
            lng: Number(data.longitude),
          });
        }
      }
    }, [data, moveMap, mapRef]);
    useEffect(() => {
      if (userPos && mapRef) {
        moveMap(userPos);
        updateFilters({
          near: `${userPos.lat},${userPos.lng}`,
        });
      }
      else if (mapRef) {
        handleCenterChange()
      }
    }, [userPos, moveMap, mapRef]);
    return (
      <div className={styles.container}>
        <Filters
          visible={!isStreetViewOpen}
          isLoading={isLoading}
          isEmpty={!isLoading && data?.libraries.length === 0}
          filters={filters}
          updateFilters={updateFilters}
          isMobile={isMobile}
          setUserPos={setUserPos}
          displayLabel={displayLabel}
          setDisplayLabel={setDisplayLabel}
        />
        {isLoaded ? (
          <GoogleMap
            mapContainerClassName={styles.map}
            center={DEFAULT_CENTER}
            zoom={14}
            onLoad={onLoad}
            onUnmount={onUnmount}
            onCenterChanged={handleCenterChange}
            onZoomChanged={handleCenterChange}
            onClick={handleMapClick}
            options={{
              mapTypeControl: false,
              fullscreenControl: !isMobile,
              gestureHandling: "greedy",
              draggable: true,
            }}
          >
            <StreetViewPanorama
              onLoad={handleStreetViewLoad}
              onVisibleChanged={handleStreetViewVisibleChange}
              options={{
                fullscreenControl: false,
              }}
            />
            {userPos && (
              <Marker
                position={userPos}
                icon={{
                  path: window.google.maps.SymbolPath.CIRCLE,
                  fillColor: "#00a3f1",
                  fillOpacity: 0.6,
                  strokeColor: "#fff",
                  strokeOpacity: 1,
                  strokeWeight: 3,
                  scale: 8,
                }}
              />
            )}
            <LibraryMarkers
              libraries={data?.libraries}
              onMarkerClick={handleMarkerClick}
              onClusterClick={handleClusterClick}
            />
            {openLibraryInfo && (
              <LibraryInfoWindow
                isMobile={isMobile}
                library={openLibraryInfo}
                setOpenLibraryInfo={setOpenLibraryInfo}
              />
            )}
          </GoogleMap>
        ) : (
          <div>Loading</div>
        )}
      </div>
    );
  }
);

export default withIsMobile(LibraryMapPage, 720);
