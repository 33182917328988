import {forwardRef} from 'react';
import {useFormContext,Controller} from 'react-hook-form';
import withField from '@components/Form/Fields/withField';
import styles from './index.module.css';
import {classNames} from '@utils';

export const SelectComponent = forwardRef(({options, placeholder, className, ...props},ref) => (
  <select {...props} className={classNames(props.disabled && styles.disabled, className)} ref={ref}>
    {placeholder && <option value="" disabled={props.unclearable} selected>{placeholder}</option>}
    {(options && options.length > 0) ? (
      options.map((option,i) =>
        <option key={option.value || i} value={option.value} disabled={option.disabled}>{option.name}</option>
      )
    ) : null}
  </select>
))

export const ControlledSelectComponent = ({as: PassedSelectComponent = SelectComponent,name,...props}) => (
  <Controller
    name={name}
    render={({ field }) => (
      <PassedSelectComponent
        {...field}
        {...props}
      />
    )}
  />
)
export const ControlledSelect = withField(ControlledSelectComponent)

const Select = withField(props => {
  const { register } = useFormContext();
  return <SelectComponent {...props} {...register(props.name)} />
})

export default Select;
